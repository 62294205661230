<template>
  <div class="my-orders">
    <top-bar back backroute="support" :title="$app.secure.clean($n.t('18.4'))" />
    <div class="container">
      <div class="wrap">
        <div v-for="item in orders" :key="item.id" class="item" :class="{'opened': item.id === openedId}"
             @click="open(item)">
          <div class="title">{{ item.title }}</div>
          <div class="status">{{ item.status }}</div>
          <div class="date">{{ item.date }}</div>
          <div class="img">
            <n-icon icon="plus" />
          </div>
          <div v-if="openedId === item.id" class="content">
            <div class="order-number">
              Заявка №{{ item.id + 1 }}
            </div>
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      openedId: null,
      orders: [
        {
          id: 0,
          title: 'Тема заявки',
          status: 'В обработке',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
        {
          id: 1,
          title: 'Тема заявки',
          status: 'Завершено',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
        {
          id: 2,
          title: 'Тема заявки',
          status: 'Завершено',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
        {
          id: 3,
          title: 'Тема заявки',
          status: 'Завершено',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
        {
          id: 4,
          title: 'Тема заявки',
          status: 'Завершено',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
        {
          id: 5,
          title: 'Тема заявки',
          status: 'Завершено',
          date: '15.12.2022, 15:22',
          desc: ' As a creative agency we work with you to develop solutions to address your brand needs. That includes various aspects of brand planning and strategy, marketing and design.',
        },
      ],
    }
  },
  methods: {
    open(item) {
      this.openedId = this.openedId === item.id ? null : item.id
    },
  },
}
</script>

<style scoped lang="scss">
.my-orders {
  .wrap {
    .item {
      padding: 16px 16px 26px;
      border: 1px solid #D9D9D9;
      border-radius: 12px;
      position: relative;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      .img {
        position: absolute;
        right: 16px;
        top: 16px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #FAAD14;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(0deg);
        transition: all .2s ease-in-out;
      }


      &.opened {
        .img {
          background: #1E1E1E;
          transform: rotate(45deg);
        }
      }

      .status{
        font-weight: 400;
        font-size: 16px;
        color: #FAAD14;
        margin-bottom: 4px;
      }

      .date{
        font-weight: 400;
        font-size: 16px;
        color: #8C8C8C;
      }

      .title {
        font-weight: 500;
        font-size: 20px;
        color: #102E4F;
        padding-right: 45px;
      }

      .order-number{
        font-weight: 500;
        font-size: 16px;
        color: #102E4F;
      }

      .content {
        font-weight: 400;
        font-size: 16px;
        color: #102E4F;
        padding-top: 20px;
      }
    }
  }
}
</style>
